import { graphql, Link } from "gatsby";
import React from "react";
import "./post.css";

export const query = graphql`
query {
  allFile(filter: {sourceInstanceName: {eq: "posts"}}) {
    edges {
      node {
        name
      }
    }
  }
}
`;

export default function ({ data }: { data: any }) {
  return <div className="posts-list">
    {
      data.allFile.edges.map(
        (edge: any) => <div key={edge.node.name}>
          <Link to={`./${edge.node.name}`}>{edge.node.name}</Link>
        </div>
      )
    }
  </div>
}